export const removeHtmlTagsFromStr = (str) => {
  return str.replace(/(<([^>]+)>)/ig, '')
}

export const getFaqSchema = (faq) => {
  const mainEntity = faq?.reduce((acc, { question, answer }) => {
    acc.push({
      "@type": "Question",
      "name": question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": removeHtmlTagsFromStr(answer)
      }
    })
    return acc;
  }, [])

  return mainEntity
}